import React, { useEffect, useState } from 'react'
import CityWarehouseDetailContent from './Detail'
import CityWarehouseListContent from './List'
import apiCollection from '../../api'
import { ICityWarehouseItem } from '../../api/apiModel/cityWarehouse'
import { EnumMenuTypes, EnumOperateType } from '../../types/enums'
import { message, Spin } from 'antd'
import { IOwnStoreItem } from '../../api/apiModel/ownStore'
import { IPageInfo } from '../../types/interface'
import Menu from '../tools/Menu'

export interface IOperateCallbackParams {
  item?: ICityWarehouseItem
  filter?: object
  pageInfo?: IPageInfo
}

const WholesaleContent = () => {
  const [isShowDetail, displayDetailPage] = useState<boolean>(false)
  const [cityWarehouseList, setCityWarehouseList] = useState<
    ICityWarehouseItem[]
  >([])
  const [editData, setEditData] = useState<ICityWarehouseItem>()
  const [filterData, setFilterData] = useState<object>({})
  const [pageInfo, setPageInfo] = useState<IPageInfo>({ page: 1, size: 10 })
  const [isLoading, setLoging] = useState<boolean>(false)
  const [storeList, setStoreList] = useState<IOwnStoreItem[]>([])
  const [total, setTotal] = useState<number>(0)
  const [cityCodes, setCityCodes] = useState<number[]>()

  const getCityWarehouseList = async () => {
    setLoging(true)
    const result = await apiCollection.getCityWarehouse({
      ...filterData,
      ...pageInfo,
    })

    if (result.success) {
      const { data: list, total } = result.data
      setCityWarehouseList(list)
      setTotal(total)
    } else {
      message.error(result.message)
      setCityWarehouseList([])
      setTotal(0)
    }

    setLoging(false)
  }

  const getAllCityWarehouseList = async () => {
    const result = await apiCollection.getCityWarehouse({})

    if (result.success) {
      const codes = result.data.data.map((item) => item.City)
      const deDuplicationCodes = [...new Set(codes)]
      setCityCodes(deDuplicationCodes)
    }
  }

  const operateCallback = (
    type: EnumOperateType,
    data?: IOperateCallbackParams
  ) => {
    switch (type) {
      case EnumOperateType.Update:
        displayDetailPage(true)
        setEditData(data.item)
        break

      case EnumOperateType.Add:
        displayDetailPage(true)
        break

      case EnumOperateType.Cancel:
        setEditData(null)
        displayDetailPage(false)
        break

      case EnumOperateType.Submit:
        setEditData(null)
        displayDetailPage(false)
        getCityWarehouseList()
        break

      case EnumOperateType.Filter:
        setFilterData(data.filter)
        setPageInfo((current) => ({ ...current, page: 1 }))
        break

      case EnumOperateType.ChangePage:
        setPageInfo((current) => ({ ...current, ...data.pageInfo }))
        break
    }
  }

  const getStoreList = async () => {
    const stores = await apiCollection.getOwnStoreList({})
    stores.success && setStoreList(stores.data.data)
  }

  useEffect(() => {
    getCityWarehouseList()
  }, [pageInfo])

  useEffect(() => {
    getStoreList()
    getAllCityWarehouseList()
  }, [])

  return (
    <Spin spinning={isLoading} delay={500}>
      <Menu
        current={EnumMenuTypes.CityWarehouse}
        refreshPage={() => {
          operateCallback(EnumOperateType.Cancel)
        }}
      />
      {isShowDetail && (
        <CityWarehouseDetailContent
          isEdit={!!editData?.NAV_Code}
          editData={editData}
          storeList={storeList}
          operateCallback={operateCallback}
        />
      )}
      {!isShowDetail && (
        <CityWarehouseListContent
          filterData={filterData}
          list={cityWarehouseList}
          total={total}
          pageInfo={pageInfo}
          cityCodes={cityCodes}
          storeList={storeList}
          operateCallback={operateCallback}
        />
      )}
    </Spin>
  )
}

export default WholesaleContent
