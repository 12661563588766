import React from 'react'
import { TablePaginationConfig } from 'antd'
import { useStoreViewContext } from '../../context/storeViewContext/StoreViewContext'
import { EnumConfigType } from '../../types/enums'
import { IGetCityWarehouseRequest } from '../../api/apiModel/cityWarehouse'
import { ICityWarehouseItem } from '../../api/apiModel/cityWarehouse'
import { IOwnStoreItem } from '../../api/apiModel/ownStore'
import { IPageInfo } from '../../types/interface'
import Table from '../tools/Table'

interface ICityWarehouseTableProps {
  total: number
  list: IGetCityWarehouseRequest[]
  storeList?: IOwnStoreItem[]
  pageInfo: IPageInfo
  onPageChange: (props: { size: number; page: number }) => void
  onClickByNo: (data: ICityWarehouseItem) => void
}

const CityWarehouseTable = ({
  total,
  list,
  storeList,
  pageInfo,
  onPageChange,
  onClickByNo,
  ...props
}: ICityWarehouseTableProps) => {
  const { configMap, areaList, employeeList } = useStoreViewContext()

  const columns = [
    {
      title: 'NAV CODE',
      dataIndex: 'NAV_Code',
      fixed: true,
      render: (text, item) => (
        <a
          onClick={(e) => {
            onClickByNo(item)
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Name CN',
      dataIndex: 'Name_CN',
      fixed: true,
    },
    {
      title: 'Name Eng',
      dataIndex: 'Name_EN',
    },
    {
      title: 'City',
      dataIndex: 'City',
      render: (id) => (
        <span>{areaList?.find((item) => item.Id == id)?.Name_CN}</span>
      ),
    },
    {
      title: 'Store CN Code',
      dataIndex: 'Store_Code',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Store Name CN',
      dataIndex: 'Store_Code',
      render: (id) => (
        <span>{storeList?.find((item) => item.Store_Code == id)?.Name_CN}</span>
      ),
    },
    {
      title: 'New Region',
      dataIndex: 'Region',
      render: (id) => (
        <span>
          {
            configMap
              .get(EnumConfigType.Store_Region)
              ?.find((item) => item.Id === id)?.Value
          }
        </span>
      ),
    },
    {
      title: 'CM',
      dataIndex: 'CM',
      render: (id) => (
        <span>
          {employeeList?.find((item) => item.Employee_No == id)?.Name_CN}
        </span>
      ),
    },
  ]
  const data = list.map((item) => ({ ...item, key: item.NAV_Code }))

  const onChangeByTable = (pagination: TablePaginationConfig) => {
    const { pageSize: size, current: page } = pagination
    onPageChange({ size, page })
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChangeByTable}
        scroll={{ x: 1200 }}
        pagination={{
          total,
          showTotal:(total) => `Total ${total} items`,
          current: pageInfo.page,
          pageSize: pageInfo.size,
          showSizeChanger: true,
        }}
      />
    </>
  )
}

export default CityWarehouseTable
