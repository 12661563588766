import { EnumFormFieldType, EnumRolePostion } from '../../types/enums'
import { IFormField } from '../../types/interface'

export const fieldsList: IFormField[] = [
  {
    name: 'Region',
    label: 'New Region',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'regionList',
    col: 12,
    placeholder: '',
    onChange: ({ value, form, optionsMap }) => {
      const RMTypeId =
        optionsMap.employeeType?.find(
          (item) => item.Value == EnumRolePostion.RM
        )?.Id ?? 0
      const BDTypeId =
        optionsMap.employeeType?.find(
          (item) => item.Value == EnumRolePostion.BD
        )?.Id ?? 0
      const RMId =
        optionsMap.employeeList?.find(
          (item) => item.Position == RMTypeId && item.Region == value
        )?.Employee_No ?? null
      const BDId =
        optionsMap.employeeList?.find(
          (item) => item.Position == BDTypeId && item.Region == value
        )?.Employee_No ?? null

      form.setFields([
        { name: 'RM', value: RMId },
        { name: 'BD', value: BDId },
      ])
    },
  },
  {
    name: 'City',
    label: 'City',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'cityList',
    col: 12,
  },
  {
    name: 'NAV_Code',
    label: 'NAV CODE',
    require: true,
    type: EnumFormFieldType.Input,
    rules: [
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '只能输入数字或字母',
      },
    ],
    getValueFromEvent: (event, form) => {
      return event.target.value.replace(/[^0-9a-zA-Z]+/g, '')
    },
  },
  {
    name: 'Name_CN',
    label: 'Name CN',
    require: true,
    type: EnumFormFieldType.Input,
    col: 12,
    rules: [
      {
        pattern: /^[\u4e00-\u9fa5-&0-9A-Za-z]+$/,
        message: '只能输入中文字符,字母或数字',
      },
    ],
  },
  {
    name: 'Name_EN',
    label: 'Name Eng',
    require: true,
    type: EnumFormFieldType.Input,
    col: 12,
    getValueFromEvent: (event) => {
      return event.target.value.replace(/[^-&0-9A-Za-z ]+/g, '')
    },
    rules: [
      {
        validator: (_, value) => {
          if (!value) return Promise.resolve('')
          if (!value.trim())
            return Promise.reject(new Error('Input something!'))
          return Promise.resolve()
        },
      },
    ],
  },
  {
    name: 'Store_Code', // TODO
    label: 'Store',
    type: EnumFormFieldType.Select,
    require: true,
    optionsName: 'CNCodeList',
  },
  {
    name: 'RM',
    label: EnumRolePostion.RM,
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'employeeList',
    extra: {
      disabled: true,
    },
    col: 12,
  },
  {
    name: 'BD',
    label: EnumRolePostion.BD,
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'employeeList',
    extra: {
      disabled: true,
    },
    col: 12,
  },
  {
    name: 'CM', // TODO
    label: 'CM',
    type: EnumFormFieldType.Select,
    optionsName: 'CMList',
    col: 12,
  },
]

export const filterFields: IFormField[] = [
  {
    name: 'NAV_Code',
    label: 'NAV CODE',
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Store_Code',
    label: 'Store CN Code',
    type: EnumFormFieldType.AutoComplete,
    optionsName: 'storeCodeList',
    col: 12,
  },
  {
    name: 'Region',
    label: 'New Region',
    type: EnumFormFieldType.Select,
    optionsName: 'regionList',
    col: 12,
  },
  {
    name: 'CM',
    label: 'CM',
    type: EnumFormFieldType.Select,
    optionsName: 'CMList',
    col: 12,
  },
  {
    name: 'City',
    label: 'City',
    type: EnumFormFieldType.Select,
    optionsName: 'CityList',
    col: 12,
  },
]
