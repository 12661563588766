import React from 'react'

import { AuthorizationProvider } from '../context/authorizationContext/AuthorizationContext'
import { StoreViewProvider } from '../context/storeViewContext/StoreViewContext'
import Header from '../component/tools/Header'
import BodyContainer from '../component/layout/BodyContainer'
import CityWarehouse from '../component/cityWarehouse/CityWarehouse'

import { EnumMenuTypes } from '../types/enums'

const CityWarehousePageContainer = () => {
  return (
    <AuthorizationProvider>
      <StoreViewProvider>
        <Header title={EnumMenuTypes.CityWarehouse} />
        <BodyContainer>
          <CityWarehouse />
        </BodyContainer>
      </StoreViewProvider>
    </AuthorizationProvider>
  )
}

export default CityWarehousePageContainer
