import React, { useEffect, useMemo, useState } from 'react'
import { fieldsList } from './FormField'
import FormContent from '../form/Form'
import { useStoreViewContext } from '../../context/storeViewContext/StoreViewContext'
import {
  EnumConfigType,
  EnumOperateType,
  EnumRolePostion,
} from '../../types/enums'
import apiCollection from '../../api'
import { ICityWarehouseItem } from '../../api/apiModel/cityWarehouse'
import { IFromMapItem } from '../../types/interface'
import { IOwnStoreItem } from '../../api/apiModel/ownStore'
import { message } from 'antd'

interface CityWarehouseDetailProps {
  isEdit?: boolean
  editData?: ICityWarehouseItem
  storeList?: IOwnStoreItem[]
  operateCallback: (type: EnumOperateType) => void
}

const CityWarehouseDetailContent = ({
  isEdit,
  editData,
  storeList,
  operateCallback,
}: CityWarehouseDetailProps) => {
  const { configMap, areaList, employeeList } = useStoreViewContext()

  const [initData, setInitData] = useState(null)

  const optionsMap = useMemo(() => {
    let cityList = []
    if (configMap.size && !!areaList?.length) {
      const areaLevelTypeList = configMap.get(EnumConfigType.Area_Level)
      const cityTypeId = areaLevelTypeList?.find(
        (tItem) => tItem.Value == '市'
      )?.Id
      cityList = areaList?.reduce(
        (preList, item) =>
          item.Level == cityTypeId
            ? preList.concat({ ...item, value: item.Name_CN })
            : preList,
        []
      )
    }

    const CNCodeList = storeList?.reduce(
      (preList, item) =>
        preList.concat({
          Id: item.Store_Code,
          Value: `${item.Store_Code} -- ${item.Name_CN}`,
        }),
      []
    )

    const CMTypeId = configMap
      .get(EnumConfigType.Position)
      ?.find((item) => item.Value === EnumRolePostion.CM)?.Id

    const CMList = employeeList.filter((item) => item.Position == CMTypeId)
    return {
      employeeType: configMap.get(EnumConfigType.Position),
      employeeList,
      CNCodeList,
      regionList: configMap.get(EnumConfigType.Store_Region),
      cityList,
      CMList,
    }
  }, [employeeList, configMap, areaList, storeList])

  const formMap = useMemo((): IFromMapItem[] => {
    const fields =
      fieldsList?.map((item) =>
        ['Region'].includes(item.name)
          ? { ...item, extra: { ...item.extra, disabled: isEdit } }
          : item
      ) ?? []
    return [
      {
        panelName: 'City Warehouse Info(City Warehouse)',
        fieldsList: fields,
        showSubmitButton: true,
        showCancelButton: true,
      },
    ]
  }, [fieldsList, isEdit])

  const initFormatData = () => {
    if (!editData || !Object.keys(editData).length) return
    const Store_Name_CN = storeList?.find(
      (item) => item.Store_Code === editData.Store_Code
    )?.Name_CN
    setInitData({ ...editData, Store_Name_CN })
  }

  const submitCallback = async (formData) => {
    const result = isEdit
      ? await apiCollection.updateCityWarehouseDetailInfo({
          ...formData,
          OldNavCode: editData?.NAV_Code,
        })
      : await apiCollection.createCityWarehouseDetailInfo(formData)
    if (result.success) {
      operateCallback(EnumOperateType.Submit)
    } else {
      message.error(result.message)
      throw Error(result.message)
    }
  }

  const cancelCallback = () => {
    operateCallback(EnumOperateType.Cancel)
  }

  useEffect(() => {
    initFormatData()
  }, [editData])

  return (
    <>
      <FormContent
        formMap={formMap}
        optionsMap={optionsMap}
        isEdit={isEdit}
        initData={initData}
        formKeyName={initData?.NAV_Code}
        submitCallback={submitCallback}
        cancelCallback={cancelCallback}
      />
    </>
  )
}

export default CityWarehouseDetailContent
