import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { filterFields } from './FormField'
import FormContent from '../form/Form'
import { useStoreViewContext } from '../../context/storeViewContext/StoreViewContext'
import { EnumConfigType, EnumExcelType } from '../../types/enums'
import { IFromMapItem } from '../../types/interface'
import Download from './../icon/Download'
import IconContent from './../icon/IconContent'
import { PlusOutlined } from '@ant-design/icons'
import { IOwnStoreItem } from '../../api/apiModel/ownStore'

const CityWarehouseFilterContainer = styled.div`
  padding-bottom: 4.8rem;
  position: relative;
`

interface ICityWarehouseFilterProps {
  filterData: object
  cityCodes: number[]
  storeList: IOwnStoreItem[]
  changeFilter: (filterData: object) => void
  addCityWarehouse: () => void
}

const CityWarehouseFilterContent = ({
  filterData,
  cityCodes,
  storeList,
  changeFilter,
  addCityWarehouse,
}: ICityWarehouseFilterProps) => {
  const { configMap, employeeList, areaList } = useStoreViewContext()

  const optionsMap = useMemo(() => {
    const CMTypeId = configMap
      .get(EnumConfigType.Position)
      ?.find((item) => item.Value == 'CM')?.Id
    const CMList = employeeList.filter((item) => item.Position == CMTypeId)

    const CityTypeId = configMap
      .get(EnumConfigType.Area_Level)
      ?.find((item) => item.Value == '市')?.Id
    const CityList = areaList
      ?.filter(
        (item) => item.Level == CityTypeId && cityCodes.includes(item.Id)
      )
      .map((item) => ({ ...item, Value: item.Name_CN }))

    const storeCodeList =
      storeList?.map((item) => ({ value: item?.Store_Code })) ?? []

    return {
      regionList: configMap.get(EnumConfigType.Store_Region),
      CMList,
      CityList,
      storeCodeList,
    }
  }, [configMap, employeeList, areaList, cityCodes, storeList])

  const formMap: IFromMapItem[] = [
    {
      panelName: 'Store Info(CityWarehouse) Filter',
      fieldsList: filterFields,
      showSubmitButton: true,
      submitButtonLabel: '搜索',
      cancelButtonLabel: '重置',
      showCancelButton: true,
      buttonPosition: 'right',
      customFormButton: [
        <Download fileUrl={`report/${EnumExcelType.StoreReport}`} />,
        <IconContent
          icon={PlusOutlined}
          tips={'新增仓库'}
          clickCallback={addCityWarehouse}
        />,
      ],
    },
  ]

  const resetFilter = () => {
    changeFilter({})
  }

  return (
    <CityWarehouseFilterContainer>
      <FormContent
        isEdit={true}
        initData={filterData}
        hiddenPanel={true}
        disableCatch={true}
        formMap={formMap}
        optionsMap={optionsMap}
        expandIcon={() => ''}
        submitCallback={changeFilter}
        cancelCallback={resetFilter}
      />
    </CityWarehouseFilterContainer>
  )
}

export default CityWarehouseFilterContent
