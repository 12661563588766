import React from 'react'
import { ICityWarehouseItem } from '../../api/apiModel/cityWarehouse'
import CityWarehouseTable from './Table'
import CityWarehouseFilter from './Filter'
import { EnumOperateType } from '../../types/enums'
import { IOperateCallbackParams } from './CityWarehouse'
import { IOwnStoreItem } from '../../api/apiModel/ownStore'
import { IPageInfo } from '../../types/interface'

interface ICityWarehouseProps {
  list: ICityWarehouseItem[]
  filterData: object
  total: number
  pageInfo: IPageInfo
  cityCodes: number[]
  storeList: IOwnStoreItem[]
  operateCallback: (
    type: EnumOperateType,
    date?: IOperateCallbackParams
  ) => void
}

const CityWarehouseListContent = ({
  list,
  filterData,
  total,
  pageInfo,
  cityCodes,
  storeList,
  operateCallback,
  ...props
}: ICityWarehouseProps) => {
  const changeFilter = (data: object) => {
    const filter = {}
    for (const [key, value] of Object.entries(data)) {
      if (value) filter[key] = value
    }
    operateCallback(EnumOperateType.Filter, { filter })
  }

  return (
    <>
      <CityWarehouseFilter
        filterData={filterData}
        cityCodes={cityCodes}
        storeList={storeList}
        changeFilter={changeFilter}
        addCityWarehouse={() => {
          operateCallback(EnumOperateType.Add)
        }}
      />
      <CityWarehouseTable
        {...props}
        list={list}
        total={total}
        storeList={storeList}
        pageInfo={pageInfo}
        onPageChange={(pageInfo) =>
          operateCallback(EnumOperateType.ChangePage, { pageInfo })
        }
        onClickByNo={(item) => {
          operateCallback(EnumOperateType.Update, { item })
        }}
      />
    </>
  )
}

export default CityWarehouseListContent
